<!-- <template>
  <div class="h-100">
    <div class="h-100">
      <v-btn
        class="mt-4 p-4 shadow-lg mx-2"
        elevated
        @click.stop="drawer = !drawer"
      >
        <v-icon class="text-white" color="white"> md-menu </v-icon>
      </v-btn>
      <router-view />
    </div>
    <v-navigation-drawer v-model="drawer" location="left">
      <v-list :items="items"></v-list>
    </v-navigation-drawer>
  </div>
</template> -->
<!-- <template>
  <div class="h-100">
    <v-layout class="h-100">
      <v-navigation-drawer v-model="drawer" location="left" class="vh-100">
        <div class="vh-100">
          <div>test</div>
        </div>
      </v-navigation-drawer>

      <div class="h-100 w-100 volunteer-container">
        <v-app-bar-nav-icon
          variant="text"
          color="secondary"
          class="right-0 end-0"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <router-view />
      </div>
    </v-layout>
  </div>
</template> -->

<template>
  <v-app>
    <v-main>
      <div class="h-100 container p-0">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script>
export default {
  mounted() {
    this.$vuetify.theme.themes.light.primary = "red";
    this.$store.dispatch("volunteer/check");
    if (this.volunteer) {
      this.$router.push({ name: "event:home" });
    }
  },
  computed: {
    volunteer() {
      return this.$store.state.volunteer;
    },
  },
};
</script>

<style lang="scss">
.container {
  background: $ct-black;
  color: white;
  height: 100%;
}
.z-1 {
  z-index: 100;
}
.z-2 {
  z-index: 200;
}

.bg-dark {
  background: $ct-black;
}
</style>
